






import { Component, Vue } from 'vue-property-decorator'
import { organisationDocumentsModule, stateModule } from '@/store'

import { OrganisationDocumentResource } from '@/models/organisationDocuments/OrganisationDocumentResource'

import OrganisationDocumentDetailsPartial from '@/components/document/OrganisationDetails.vue'

@Component({
  components: {
    OrganisationDocumentDetailsPartial
  }
})
export default class DocumentDetails extends Vue {


  private get detail(): OrganisationDocumentResource | null {
    return organisationDocumentsModule.detail
  }

  private async submit(): Promise<void> {
    await organisationDocumentsModule.get()
    stateModule.setLoading(false)
  }

}
